import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
/* eslint-disable no-multi-str */
import Cookies from 'js-cookie';
import LightBox from 'vue-image-lightbox';
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css';
import XLSX from 'xlsx';
import axios from 'axios';
import { getToken } from '@/utils/auth';
import Editor from '@tinymce/tinymce-vue';
import { parseTime } from '@/utils';
import Vue from 'vue';
import { deleteItem } from '@/api/common';
import { routeTo } from '@/router';
const file = {
  methods: {
    viewUrl(code) {
      return `${Vue.prototype.$appBaseApi || process.env.VUE_APP_BASE_API}${process.env.VUE_APP_MANAGE_API}/file/${code}/view`;
    }
  }
};
const image = {
  components: {
    LightBox
  },
  data() {
    return {
      srcList: ['']
    };
  },
  methods: {
    viewImage(src) {
      this.srcList[0] = {
        src
      };
      this.$refs.lightbox.showImage(0);
    }
  }
};
const CONVERTED_LABELS = [];
const LANGUAGE_MAPPING = {
  name: {
    zh: 'name',
    en: 'en_name'
  },
  stu_name: {
    zh: 'name',
    en: ['py_name', 'en_name']
  },
  course_snapshot_title: {
    zh: 'course_snapshot_title',
    en: 'course_snapshot_en_title'
  },
  student_name: {
    zh: 'student_name',
    en: ['student_py_name', 'student_en_name']
  },
  stu_user_name: {
    zh: 'user_name',
    en: ['user_py_name', 'user_en_name']
  },
  subject_category_name: {
    zh: 'subject_category_name',
    en: 'subject_category_en_name'
  },
  title: {
    zh: 'title',
    en: 'en_title'
  },
  teacher_name: {
    zh: 'teacher_name',
    en: 'teacher_en_name'
  },
  courseware_snapshot_title: {
    zh: 'courseware_snapshot_title',
    en: 'courseware_snapshot_en_title'
  },
  user_name: {
    zh: 'user_name',
    en: 'user_en_name'
  }
};
function langKey(key, locale) {
  const val = LANGUAGE_MAPPING[key];
  if (val != null) {
    return locale === 'zh' ? val.zh : val.en;
  }
  return key;
}
function studentEnNameKey(key, val, locale) {
  if (locale === 'zh') {
    if (key === 'stu_name' && val.en_name != null && val.en_name.length > 0) {
      return 'en_name';
    }
    if (key === 'student_name' && val.student_en_name != null && val.student_en_name.length > 0) {
      return 'student_en_name';
    }
    if (key === 'stu_user_name' && val.user_en_name != null && val.user_en_name.length > 0) {
      return 'user_en_name';
    }
  }
  return null;
}
function langValue(val, path, supportLang, locale) {
  const paths = path.split('.');
  const last = paths[paths.length - 1];
  let ret = {
    ...val
  };
  paths.forEach(element => {
    if (ret != null) {
      if (supportLang === true && element === last) {
        const key = langKey(element, locale);
        if (typeof key === 'string') {
          if (ret[key] !== undefined) {
            const enNameKey = studentEnNameKey(element, val, locale);
            if (enNameKey != null) {
              ret = `${ret[key]} (${ret[enNameKey]})`;
            } else {
              ret = ret[key];
            }
          } else {
            ret = ret[element];
          }
        } else {
          ret = `${ret[key[0]]}.${ret[key[1]]}`;
        }
      } else {
        ret = ret[element];
      }
    }
  });
  return ret;
}
const lang = {
  methods: {
    languageKey(key) {
      return langKey(key, this.$i18n.locale);
    },
    valueFromPath(val, path, supportLang) {
      return langValue(val, path, supportLang, this.$i18n.locale);
    }
  }
};
const list = {
  data() {
    return {
      endpoint: null,
      meta: {
        total: 0,
        current_page: 1,
        page_size: 10
      },
      query: {
        page: 1,
        per_page: 10
      },
      dataList: [],
      listloading: false,
      editDialogVisible: false,
      editObject: null,
      columns: []
    };
  },
  created() {
    const pageSize = window.localStorage.getItem('per_page');
    if (pageSize) {
      this.meta.page_size = parseInt(pageSize, 10);
      this.query.per_page = parseInt(pageSize, 10);
    }
  },
  mounted() {
    setTimeout(() => {
      this.computeTableHeight();
      this.$emit('tableHeightComputed', this);
    }, 200);
  },
  methods: {
    computeTableHeight() {
      const paging = document.querySelector('.pagination-block');
      const containerPadding = 30;
      const pageHeight = document.documentElement.clientHeight;
      if (paging) {
        const paginationBottom = document.querySelector('.pagination-block').getBoundingClientRect().bottom;
        const paginationPadding = 0;
        const h = pageHeight - paginationBottom - containerPadding - paginationPadding;
        if (h > 0) {
          this.tableHeight = h;
        }
      }
    },
    handlePageTurn(val) {
      this.query.page = val;
      this.loadList();
    },
    handlePageSize(size) {
      window.localStorage.setItem('per_page', size);
      this.query.per_page = size;
      this.loadList();
    },
    handleReset() {
      this.query.page = 1;
      this.loadList();
    },
    handleAdd() {
      this.editObject = null;
      this.editDialogVisible = true;
    },
    handleEdit(val) {
      this.editObject = val;
      this.editDialogVisible = true;
    },
    handleDelete(val, title, message, deleteFunc) {
      this.$confirm(this.$t(title), this.$t(message), {
        confirmButtonText: this.$t('common.delete'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {
        if (deleteFunc != null) {
          deleteFunc(val).then(() => {
            this.$message({
              message: this.$t('common.operation_success'),
              type: 'success',
              center: true
            });
            this.loadList();
          });
        } else {
          this.deleteRow(val).then(res => {
            if (res.code === 0) {
              this.$message({
                message: this.$t('common.operation_success'),
                type: 'success',
                center: true
              });
              this.loadList();
            }
          });
        }
      }).catch(() => {
        this.$message({
          message: this.$t('common.operation_cancelled'),
          type: 'warning',
          center: true
        });
      });
    },
    handleSearch() {
      this.query.page = 1;
      if (this.loadList) {
        this.loadList();
      } else {
        this.$emit('search');
      }
    },
    handleDialogConfirm() {
      this.loadList();
    },
    handleDialogClose() {
      this.editObject = null;
    },
    makeColumns(labelPrefix, columnWidthOptions) {
      if (this.dataList.length > 0) {
        if (labelPrefix == null) {
          labelPrefix = 'table';
        }
        this.columns = Object.keys(this.dataList[0]).map(k => ({
          key: k,
          label: CONVERTED_LABELS.indexOf(k) >= 0 ? `${labelPrefix}.${k}` : k,
          width: columnWidthOptions != null ? columnWidthOptions[k] || columnWidthOptions.default_width : null
        }));
      }
    },
    languageKey(key) {
      return langKey(key, this.$i18n.locale);
    },
    valueFromPath(val, path, supportLang) {
      return langValue(val, path, supportLang, this.$i18n.locale);
    },
    deleteRow(val) {
      return deleteItem(this.endpoint, val.id || val.code);
    }
  }
};
const shop = {
  computed: {
    shopCode() {
      return Cookies.get('shopCode');
    }
  }
};
const nav = {
  methods: {
    viewUserDetail(code) {
      this.$router.push({
        path: '/users/detail',
        query: {
          code
        }
      });
    },
    viewCardDetail(cardNumber) {
      this.$router.push({
        path: '/cards/detail',
        query: {
          card_number: cardNumber
        }
      });
    }
  }
};
const exportCsv = {
  data() {
    return {
      exportLoading: false,
      list_exporting: [],
      page_exporting: 1,
      per_page_exporting: 50
    };
  },
  methods: {
    handleExport(e, total, limit = 1000) {
      if (total && total > limit) {
        this.$alert(`导出数量大于${limit}条，请筛选后再导出。`, '提示', {
          type: 'warning',
          confirmButtonText: '确定'
        });
        return;
      }
      e.stopPropagation();
      this.list_exporting = [];
      this.page_exporting = 1;
      this.exportLoading = true;
      this.getExportingList();
    },
    getExportingList() {
      const start = this.per_page_exporting * (this.page_exporting - 1) + 1;
      const end = this.per_page_exporting * this.page_exporting;
      this.$notify.info({
        title: `正在加载第 ${start} - ${end} 条`,
        message: '全部加载完成后将自动导出',
        duration: 500
      });
      this.exportRequest().then(res => {
        let dataList = res.data.items;
        if (this.formatDataList) {
          dataList = this.formatDataList(dataList);
        }
        if (dataList.length) {
          this.list_exporting = this.list_exporting.concat(dataList);
        }
        const {
          total
        } = res.data;
        if (this.page_exporting * this.per_page_exporting < total) {
          this.page_exporting += 1;
          this.getExportingList();
        } else {
          this.$notify.success({
            title: '数据加载完成',
            message: '现在开始导出',
            duration: 1000
          });
          this.startExport();
        }
      }).catch(error => {
        this.$message.warning(error.message || '导出失败，请重试');
        this.exportLoading = false;
      });
    },
    outputName() {
      return 'export.xlsx';
    },
    startExport() {
      this.exportLoading = false;
      const dataList = this.list_exporting.map(item => this.exportMap(item));
      if (dataList.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(dataList);
        worksheet['!cols'] = Object.keys(dataList[0]).map(() => ({
          wpx: 80
        }));
        const newWorkbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(newWorkbook, worksheet, 'Sheet1');
        XLSX.writeFile(newWorkbook, this.outputName());
      }
      this.list_exporting = [];
    }
  }
};
const statusTag = {
  methods: {
    cardStatus(status) {
      switch (status) {
        case 0: // 未激活
        case -1:
          // 已作废
          return 'info';
        case 9:
          // 已过期
          return 'danger';
        case 2:
          // 可使用
          return 'success';
        case 4:
          // 已使用
          return 'warning';
        default:
          // 已激活
          // 送礼中
          return 'primary';
      }
    },
    giftStatus(status) {
      switch (status) {
        case 1:
          // 送礼中
          return 'primary';
        case 2:
          // 已退回
          return 'warning';
        case 3:
          // 已完成
          return 'success';
        default:
          return '';
      }
    },
    orderStatus(status) {
      switch (status) {
        case 0:
          // 待发货
          return 'primary';
        case 1:
          // 已发货
          return 'success';
        case -1:
          // 已作废
          return 'warning';
        default:
          return '';
      }
    }
  }
};
const editor = {
  components: {
    editor: Editor
  },
  data() {
    return {
      apiKey: 'fdb0jtztfpcjdguen87v3d1ybi7a583lfloz5l00b0rp9go3',
      initParams: {
        language_url: '/static/tinymce/zh_CN.js',
        language: this.$i18n.locale === 'zh' ? 'zh_CN' : '',
        height: 500,
        menubar: false,
        plugins: ['advlist autolink lists link image charmap print preview anchor', 'searchreplace visualblocks code fullscreen', 'insertdatetime media table paste code help wordcount image imagetools hr'],
        toolbar: 'undo redo | formatselect | bold italic backcolor forecolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent hr | removeformat | link image preview',
        image_dimensions: true,
        automatic_uploads: true,
        images_upload_handler(blobInfo, success, failure) {
          const data = new FormData();
          data.append('file', blobInfo.blob());
          axios.post(`${Vue.prototype.$appBaseApi || process.env.VUE_APP_BASE_API}/file/`, data, {
            headers: {
              Authorization: `hb ${getToken()}`
            }
          }).then(res => {
            success(res.data.data.view_url);
          }).catch(err => {
            failure(err);
          });
        },
        branding: false
      }
    };
  }
};
const format = {
  methods: {
    formatPrice(val) {
      return (val / 100.0).toFixed(2);
    },
    dateFromString(str) {
      str = str.split('+')[0].replace(/-/g, '/');
      return new Date(str);
    },
    stringFromDate(date) {
      return parseTime(date, '{y}-{m}-{d} {h}:{i}:{s}');
    },
    formatTime(val) {
      if (val) {
        return parseTime(this.dateFromString(val), '{y}-{m}-{d} {h}:{i}:{s}');
      }
      return val;
    },
    formatTimeInData(data) {
      return {
        ...data,
        update_at: this.formatTime(data.update_at),
        create_at: this.formatTime(data.create_at),
        publish_at: this.formatTime(data.publish_at),
        last_reply_at: this.formatTime(data.last_reply_at),
        reply_at: this.formatTime(data.reply_at),
        pay_at: this.formatTime(data.pay_at)
      };
    },
    splitLines(str) {
      if (str === null) {
        return [];
      }
      if (str.length <= 30) {
        return [str];
      }
      return str.match(/(.|[\r\n]){1,30}/g);
    },
    formatToHourMinute(num) {
      if (typeof num !== 'number') {
        return '';
      }
      const hours = Math.floor(num / 60);
      const mins = num - hours * 60;
      return `${hours}${this.$t('common.hour')}${mins}${this.$t('common.minute')}`;
    }
  }
};
const sort = {
  data() {
    return {
      sortKey: null
    };
  },
  methods: {
    sort(str1, str2) {
      if (this.sortKey == null) {
        return 0;
      }
      str1 = str1[this.sortKey];
      str2 = str2[this.sortKey];
      let res = 0;
      for (let i = 0;; i += 1) {
        if (!str1[i] || !str2[i]) {
          res = str1.length - str2.length;
          break;
        }
        const char1 = str1[i];
        const char1Type = this.getChartType(char1);
        const char2 = str2[i];
        const char2Type = this.getChartType(char2);
        // console.log(char1Type, char2Type);
        // 类型相同的逐个比较字符
        if (char1Type[0] === char2Type[0]) {
          if (char1 === char2) {
            // eslint-disable-next-line no-continue
            continue;
          } else {
            if (char1Type[0] === 'zh') {
              res = char2.localeCompare(char1);
            } else if (char1Type[0] === 'en') {
              res = char1.charCodeAt(0) - char2.charCodeAt(0);
            } else {
              res = char1 - char2;
            }
            break;
          }
        } else {
          // 类型不同的，直接用返回的数字相减
          res = char1Type[1] - char2Type[1];
          break;
        }
      }
      return res;
    },
    getChartType(char) {
      // 数字可按照排序的要求进行自定义，我这边产品的要求是
      // 数字（0->9）->大写字母（A->Z）->小写字母（a->z）->中文拼音（a->z）
      if (/^[\u4e00-\u9fa5]$/.test(char)) {
        return ['zh', 300];
      }
      if (/^[a-zA-Z]$/.test(char)) {
        return ['en', 200];
      }
      if (/^[0-9]$/.test(char)) {
        return ['number', 100];
      }
      return ['others', 999];
    }
  }
};
const formRules = {
  data() {
    return {
      rules: {}
    };
  },
  created() {
    const checkPrice = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.priceRequireRule));
      }
      if (parseInt(value * 100, 10) > 0) {
        return callback();
      }
      return callback(new Error(this.priceIncorrectRule));
    };
    this.rules = {
      title: [{
        required: true,
        trigger: 'blur',
        message: this.titleRequireRule
      }],
      en_title: [{
        required: true,
        trigger: 'blur',
        message: this.titleRequireRule
      }],
      price: [{
        required: true,
        trigger: 'blur',
        message: this.priceRequireRule
      }, {
        validator: checkPrice,
        trigger: 'blur'
      }],
      intro: [{
        required: true,
        trigger: 'blur',
        message: this.introRequireRule
      }],
      subject_category_id: [{
        required: true,
        message: this.subjectRequireRule
      }],
      grade_category_id_list: [{
        required: true,
        message: this.gradeRequireRule
      }],
      teacher_id: [{
        required: false,
        message: this.teacherRequireRule
      }]
    };
  },
  computed: {
    priceRequireRule() {
      return this.$t('rules.price_require');
    },
    priceIncorrectRule() {
      return this.$t('rules.price_incorrect');
    },
    titleRequireRule() {
      return this.$t('rules.title_require');
    },
    teacherRequireRule() {
      return this.$t('rules.teacher_require');
    },
    subjectRequireRule() {
      return this.$t('rules.subject_require');
    },
    gradeRequireRule() {
      return this.$t('rules.grade_require');
    },
    introRequireRule() {
      return this.$t('rules.intro_require');
    }
  }
};
const clicks = {
  methods: {
    detectClick(val) {
      return new Promise(resolve => {
        if (this.click) {
          clearTimeout(this.click);
          resolve(2, val);
        }
        this.click = setTimeout(() => {
          this.click = undefined;
          resolve(1, val);
        }, 200);
      });
    }
  }
};
const configurableColumns = {
  data() {
    return {
      tableColDialogVisible: false,
      tableColKey: 'tableCols'
    };
  },
  methods: {
    getTableColumns(tableName) {
      this.tableColKey = `tableCols_${tableName}`;
      let tableCols = window.localStorage.getItem(this.tableColKey);
      if (tableCols) {
        tableCols = JSON.parse(tableCols);
      } else {
        tableCols = null;
      }
      return tableCols;
    },
    addShowInTableProp(requestParams, tableName) {
      const tableCols = this.getTableColumns(tableName);
      requestParams.forEach(e => {
        this.$set(e, 'showInTable', this.showInTableValue(tableCols, e.key, true));
      });
    },
    showInTableValue(tableCols, key, defaultValue) {
      if (tableCols == null || tableCols[key] == null) {
        return defaultValue;
      }
      return tableCols[key];
    },
    updateTableCols() {
      if (this.$refs.basic) {
        this.$refs.basic.updateColumns();
      }
      window.localStorage.setItem(this.tableColKey, JSON.stringify(this.requestParams.reduce((a, c) => {
        a[c.key] = c.showInTable;
        return a;
      }, {})));
    }
  }
};
const routing = {
  data() {
    return {
      routeTo
    };
  }
};
export default {
  list,
  file,
  shop,
  nav,
  image,
  exportCsv,
  statusTag,
  editor,
  format,
  sort,
  formRules,
  lang,
  clicks,
  configurableColumns,
  routing
};