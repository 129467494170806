import { get, updateItem, update } from '@/api/common';
import store from '@/store';
import mixins from '@/utils/mixins';
import pagination from '@/components/Pagination/index.vue';
export default {
  components: {
    pagination
  },
  mixins: [mixins.list],
  data() {
    return {
      showMessageIcon: false,
      unreadMessages: '',
      drawer: false,
      activeName: 'unread',
      tableHeight: 0,
      endpoint: '/messages/'
    };
  },
  created() {
    this.handleMessage();
  },
  methods: {
    async handleMessage() {
      if (store && store.getters.permissions.length > 0) {
        if (store.getters.permissions.some(e => e.code === 'VIEW_MESSAGE')) {
          this.showMessageIcon = true;
          const {
            code,
            data
          } = await get(`${this.endpoint}unread_message_check`);
          if (code === 0) {
            const unread = data.unread_message_count || 0;
            if (unread === 0) {
              this.unreadMessages = '';
            } else if (unread < 100) {
              this.unreadMessages = `${unread}`;
            } else {
              this.unreadMessages = '99+';
            }
          }
          setTimeout(() => {
            this.handleMessage();
          }, 1000 * 60 * 1);
        }
      } else {
        setTimeout(() => {
          this.handleMessage();
        }, 1000);
      }
    },
    async showMessages() {
      setTimeout(() => {
        this.computeTableHeight();
        setTimeout(() => {
          this.computeTableHeight();
        }, 100);
      }, 100);
      this.activeName = 'unread';
      this.dataList = [];
      this.query.page = 1;
      this.loadList();
    },
    async loadList() {
      this.listloading = true;
      const list = document.getElementById('messageList');
      if (list) {
        list.scrollTo(0, 0);
      }
      const {
        code,
        data
      } = await get(this.endpoint, {
        status: this.activeName === 'unread' ? 0 : null,
        ...this.query
      });
      if (code === 0) {
        this.dataList = data.items;
        this.meta.total = data.total;
        this.meta.current_page = data.page;
      }
      this.listloading = false;
    },
    handleClick() {
      this.query.page = 1;
      this.loadList();
    },
    async handleSetUnread(row) {
      await updateItem(this.endpoint, row.id);
      this.loadList();
      this.handleMessage();
    },
    async handleClearAll() {
      await update(`${this.endpoint}clear`);
      this.loadList();
      this.handleMessage();
    }
  }
};